import * as React from "react"
import Layout from "@lekoarts/gatsby-theme-minimal-blog/src/components/layout"

const NotFound = () => (
	<Layout>
		<h1>404 - Page Not Found</h1>
		<p>ごめんね，URLが変わったかもしれない．</p>
	</Layout>
)

export default NotFound
